import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'chevron', 'board'];

  static classes = ['currentBoard', 'otherBoard'];

  static values = {
    isPanelOpened: Boolean,
    previousSibling: String,
  };

  toggleListPanel(event) {
    this.#opened = !this.#opened;
    event.preventDefault();
  }

  getPreviousSibling(event) {
    this.previousSibling = event.currentTarget.previousElementSibling.innerHTML;
    console.log('here');
  }

  switchBoard(event) {
    if (this.previousSibling === event.currentTarget.previousElementSibling.innerHTML) {
      this.boardTargets.forEach((board) => {
        const isCurrentBoard = event.currentTarget === board;
        this.otherBoardClasses.forEach((otherClass) => {
          board.classList.toggle(otherClass, !isCurrentBoard);
        });
        this.currentBoardClasses.forEach((currentClass) => {
          board.classList.toggle(currentClass, isCurrentBoard);
        });
      });
    }
  }

  get #opened() {
    return this.isPanelOpenedValue;
  }

  set #opened(opened) {
    this.element.classList.toggle('translate-y-[calc(100%-19px)]');
    this.chevronTarget.classList.toggle('rotate-180');
    this.buttonTarget.classList.toggle('border-b-white');
    this.isPanelOpenedValue = opened;
  }
}
