import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'emptyView',
    'displayView',
    'totalsView',
    'editView',
    'budgetFromInput',
    'budgetToInput',
    'editBudgetIcon',
  ];

  connect() {
    this.boundExitEditing = (event) => this.exitEditing(event, true);

    this.addNumberFormattingListeners(this.budgetFromInputTarget);
    this.addNumberFormattingListeners(this.budgetToInputTarget);
  }

  startEditing(event) {
    event.preventDefault();
    this.emptyViewTarget.classList.add('hide');
    this.displayViewTarget.classList.add('hide');
    this.editViewTarget.classList.remove('hide');
    this.budgetFromInputTarget.focus();
    document.addEventListener('click', this.boundExitEditing);
    document.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  exitEditing(event, shouldSave = false) {
    // If click is inside the budget container, don't exit
    if (event && event.type === 'click' && this.element.contains(event.target)) {
      return;
    }

    document.removeEventListener('click', this.boundExitEditing);
    document.removeEventListener('keydown', this.handleKeydown.bind(this));

    if (shouldSave) {
      this.saveBudget();
    } else {
      this.emptyViewTarget.classList.add('hide');
      this.displayViewTarget.classList.remove('hide');
      this.editViewTarget.classList.add('hide');
    }
  }

  handleKeydown(event) {
    if (event.key === 'Escape') {
      event.preventDefault();
      this.exitEditing(event, false);
    } else if (event.key === 'Enter') {
      this.saveBudget();
    }
  }

  async saveBudget() {
    const projectId = this.element.dataset['dashboard-BudgetProjectId'];
    const budgetFrom = this.budgetFromInputTarget.value.replace(/,/g, '') || null;
    const budgetTo = this.budgetToInputTarget.value.replace(/,/g, '') || null;
    const hasBudget = budgetFrom && budgetTo;
    const response = await fetch(`/projects/${projectId}/dashboard/update_budget`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ project: { budget_from: budgetFrom, budget_to: budgetTo } }),
    });

    if (!response.ok) {
      console.error('❌ Failed to update budget');
      return;
    }

    this.emptyViewTarget.classList.add('hide');
    this.displayViewTarget.classList.add('hide');
    this.editViewTarget.classList.add('hide');

    if (hasBudget) {
      this.totalsViewTarget.innerHTML = `$${this.parseBudget(budgetFrom)} – $${this.parseBudget(
        budgetTo
      )}`;
      this.displayViewTarget.classList.remove('hide');
    } else {
      this.totalsViewTarget.innerHTML = '';
      this.emptyViewTarget.classList.remove('hide');
    }
  }

  addNumberFormattingListeners(inputField) {
    inputField.dataset.rawValue = inputField.value;
    inputField.type = 'text';

    inputField.addEventListener('input', this.formatNumber.bind(this, inputField));
    inputField.addEventListener('focus', this.formatNumber.bind(this, inputField));
  }

  parseBudget(budget) {
    return budget ? parseInt(budget).toLocaleString() : '';
  }

  formatNumber(inputField) {
    const rawValue = inputField.value.replace(/\D/g, '');
    inputField.dataset.rawValue = rawValue;

    if (rawValue) {
      inputField.value = Number(rawValue).toLocaleString();
    } else {
      inputField.value = '';
    }
  }
}
