import { Controller } from 'stimulus';
import { templateContent, useTimeout } from '@/js/util';

export default class extends Controller {
  static targets = ['menu', 'label', 'labelContent', 'optionTemplate', 'input', 'defaultInput'];

  connect() {
    useTimeout(this);
    this.#syncLabelContent();
  }

  /**
   * @param {InputEvent} event
   */
  select({ target }) {
    const selected = target.checked;

    if (selected) {
      this.#synchronizeInputs(target);
    } else {
      this.#activateDefault();
    }

    this.#applied();

    this.timeout(50, () => (this.#open = false));
  }

  /**
   * @param {MouseEvent} e
   */
  toggle(e) {
    const { target } = e;

    if (this.labelTarget.contains(target)) {
      e.preventDefault();
      this.#open = !this.#open;
      return;
    }

    if (this.menuTarget.contains(target)) {
      return;
    }

    if (this.#open) {
      this.#open = false;
    }
  }

  finishAnimation() {
    if (this.#classList.contains('df-filter-dropdown--appear')) {
      this.#classList.add('df-filter-dropdown--open');
      this.#classList.remove('df-filter-dropdown--appear');
    }
    if (this.#classList.contains('df-filter-dropdown--hide')) {
      this.#classList.remove('df-filter-dropdown--hide');
    }
  }

  add({ value, label = value, type = 'or' }, beforeValue = null) {
    const beforeInputTarget = beforeValue
      ? this.inputTargets.find((input) => this.#getInputTargetValue('value', input) === beforeValue)
      : null;

    const liEl = templateContent(this.optionTemplateTarget);
    const labelEl = liEl.children[0];
    const inputEl = labelEl.children[0];
    inputEl.setAttribute(`data-${this.identifier}-value`, value);
    inputEl.setAttribute(`data-${this.identifier}-type`, type);

    const labelText = [...labelEl.childNodes].pop();
    labelText.nodeValue = label;

    this.menuTarget.firstElementChild.insertBefore(liEl, this.#getInputWrapper(beforeInputTarget));
  }

  remove(value) {
    const inputTarget = this.inputTargets.find(
      (input) => this.#getInputTargetValue('value', input) === value
    );
    this.#getInputWrapper(inputTarget).remove();

    if (this.#activateDefault()) {
      this.#applied();
    }
  }

  get values() {
    return this.inputTargets.map((input) => this.#getInputTargetValue('value', input));
  }

  get activeValues() {
    return this.#activeInputTargets((input) => this.#getInputTargetValue('value', input));
  }

  #getInputWrapper(input) {
    if (!input) {
      return null;
    }
    return input.parentElement.parentElement;
  }

  #applied() {
    this.dispatch('change', { detail: { filters: this.activeValues } });
  }

  #synchronizeInputs(activatedInput) {
    const type = this.#getInputTargetValue('type', activatedInput);

    if (type === 'or') {
      this.inputTargets.forEach((input) => {
        input.checked = input === activatedInput;
      });
    }

    this.#syncLabelContent();
  }

  #activateDefault() {
    const shouldActivateDefault = this.hasDefaultInputTarget && !this.#activeInputTargets().length;

    if (shouldActivateDefault) {
      this.defaultInputTarget.checked = true;
      this.#syncLabelContent();
    }

    return shouldActivateDefault;
  }

  #syncLabelContent() {
    const activeFilterLabels = this.#activeInputTargets((input) =>
      this.#getInputTargetValue('label', input)
    );
    this.labelContentTarget.innerHTML = activeFilterLabels.join(', ');
  }

  get #open() {
    return this.#classList.contains('df-filter-dropdown--open');
  }

  set #open(open) {
    if (this.#transitioning) {
      return;
    }

    if (open) {
      this.#classList.add('df-filter-dropdown--appear');
    } else {
      this.#classList.remove('df-filter-dropdown--open');
      this.#classList.add('df-filter-dropdown--hide');
    }
  }

  get #transitioning() {
    return (
      this.#classList.contains('df-filter-dropdown--appear') ||
      this.#classList.contains('df-filter-dropdown--hide')
    );
  }

  get #classList() {
    return this.element.classList;
  }

  /**
   * @param {function(HTMLInputElement):any} callback
   * @return {Array<any>}
   */
  #activeInputTargets(callback = null) {
    const result = [];
    this.inputTargets.forEach((input) => {
      input.checked && result.push(callback ? callback(input) : input);
    });
    return result;
  }

  /**
   * @param {String} key
   * @param {HTMLInputElement} input
   * @return {String}
   */
  #getInputTargetValue(key, input) {
    switch (key) {
      case 'label':
        return input.parentElement.innerText;
      case 'type':
        return input.getAttribute(`data-${this.identifier}-type`);
      case 'value':
        return input.getAttribute(`data-${this.identifier}-value`);
    }

    throw new Error(`unknown key '${key}' for inputTarget`);
  }
}
