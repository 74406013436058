/**
 * Our jQuery modals doesn't play nice with stimulus, as it renders model multiple times,
 * and so we receive multiple `connect/disconnect` callbacks.
 *
 * This Stimulus mixin (with ElementsHandler singleton) hooks into these jQuery modals
 */
class ElementsHandler {
  static get instance() {
    if (!this._instance) {
      this._instance = new this();
    }

    return this._instance;
  }

  constructor() {
    this.callbacks = new Map();
    this.data = new Map();
  }

  register(element, callbacks) {
    if (!this.data.has(element)) {
      this.data.set(element, { count: 0 });
    }
    this.callbacks.set(element, callbacks);

    const data = this.data.get(element);
    // unfortunately, due to Stimulus specifics, it runs all connect() callbacks
    // AFTER jQuery `modal:open` event is emitted. But we know it's going to
    // connect controller 3 times, so we just run open() callback when we
    // received third attempt to register a controller for callbacks.
    // THIS IS BAD, but we have no other choice.
    data.count += 1;
    if (data.count === 3) {
      this.runCallback(element, 'open');
    }
  }

  runCallback(element, callbackName) {
    const callback = this.callbacks.get(element)[callbackName];
    callback && callback();
  }

  runCallbackForContainer(container, callbackName) {
    for (const element of this.callbacks.keys()) {
      container.contains(element) && this.runCallback(element, callbackName);
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  $(document).on('modal:before-close', ({ target }) => {
    ElementsHandler.instance.runCallbackForContainer(target, 'beforeClose');
  });
});

const ajaxModalCallbacks = (controller, { open, beforeClose }) => {
  ElementsHandler.instance.register(controller.element, { open, beforeClose });
};

export default ajaxModalCallbacks;
