const setHeading = (editor, { value }) => {
  const level = parseInt(value);
  const validLevel = level >= 1 && level <= 6;

  validLevel && editor.chain().focus().setHeading({ level }).run();
};

const setParagraph = (editor) => {
  editor.chain().focus().setParagraph().run();
};

export default {
  'text:heading': setHeading,
  'block:paragraph': setParagraph,
};
