import Paragraph from '@tiptap/extension-paragraph';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const control = menuControlFor(editor, 'block:paragraph');
  const controlIsActive = editor.isActive('paragraph');

  control?.classList?.toggle('active', controlIsActive);
};

export default Paragraph.extend({
  onSelectionUpdate: syncMenu,
  onTransaction: syncMenu,
});
