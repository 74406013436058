import { Controller } from 'stimulus';
import { useAjaxModalCallbacks } from '@/js/util';

export default class extends Controller {
  static targets = ['row'];
  static outlets = ['filter-dropdown'];

  connect() {
    this.modalOpened = false;

    useAjaxModalCallbacks(this, {
      open: () => (this.modalOpened = true),
      beforeClose: () => (this.modalOpened = false),
    });
  }

  rowTargetDisconnected() {
    if (!this.modalOpened) {
      return;
    }

    const currentVendorNames = this.rowTargets.reduce(
      (acc, el) => acc.add(this.#getRowVendorName(el)),
      new Set()
    );

    this.#filterVendorNames.forEach((vendorName) => {
      !currentVendorNames.has(vendorName) && this.filterDropdownOutlet.remove(vendorName);
    });
  }

  rowTargetConnected(rowTarget) {
    if (!this.modalOpened) {
      return;
    }

    const possiblyNewVendor = this.#getRowVendorName(rowTarget);
    const vendorNames = this.#filterVendorNames;
    this.#filterRows(this.filterDropdownOutlet.activeValues, [rowTarget]);
    if (vendorNames.includes(possiblyNewVendor)) {
      return;
    }

    let beforeVendor = null;
    // "Unspecified" comes very last
    if (possiblyNewVendor !== 'Unspecified') {
      beforeVendor =
        vendorNames.find((vendorName) => vendorName.localeCompare(possiblyNewVendor) === 1) || null;
    }

    this.filterDropdownOutlet.add({ value: possiblyNewVendor }, beforeVendor);
  }

  filter(e) {
    const { detail } = e;
    const { filters } = detail;
    this.#filterRows(filters, this.rowTargets);
  }

  #filterRows(filters, rows) {
    const [filter] = filters;

    if (filter === '') {
      return rows.forEach((el) => el.classList.toggle('hide', false));
    }

    rows.forEach((el) => {
      el.classList.toggle('hide', this.#getRowVendorName(el) !== filter);
    });
  }

  #getRowVendorName(row) {
    return row.getAttribute(`data-${this.identifier}-vendor-name`);
  }

  get #filterVendorNames() {
    let vendorNames = this.filterDropdownOutlet.values;
    // we know first option is "protected" from modification and aren't a vendor
    vendorNames = vendorNames.slice(1);
    return vendorNames;
  }
}
