import TiptapBaseController from './tiptap_base';
import SerializeToInput from '@/js/util/tiptap/serializers/serialize_to_input';
import Config from '@/js/util/tiptap/config/builder';

export default class extends TiptapBaseController {
  static targets = ['page', 'content'];
  static values = { variant: String };

  connect() {
    super.connect();
    console.info('TiptapBaseController connected successfully');
  }

  get _element() {
    return this.pageTarget;
  }

  get _serializer() {
    return new SerializeToInput(this.contentTarget);
  }

  get _extensions() {
    return Config.for(this.variantValue).extensions;
  }

  get _commandHandlers() {
    return Config.for(this.variantValue).handlers;
  }
}
