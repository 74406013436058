import { Controller } from 'stimulus';

export default class extends Controller {
  static outlets = ['accordion'];

  manageAccordions(event) {
    this.#openOnlyAccordionById(event.params.id);
  }

  manageAccordionsAndToggleThis(event) {
    this.accordionOutlets.forEach((accordion) => {
      accordion.opened = accordion.element.id === event.params.id ? !accordion.opened : false;
    });
  }

  #openOnlyAccordionById(id) {
    this.accordionOutlets.forEach((accordion) => {
      accordion.opened = id === accordion.element.id;
    });
  }
}
