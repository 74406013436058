/* globals validateMessageBeforeShow deleteMessage updateMessage */

import { Controller } from 'stimulus';
import consumer from '@/js/channels/consumer';

export default class extends Controller {
  // eslint-disable-next-line no-unused-vars
  static targets = [
    'unreadCount',
    'inputContainer',
    'clientMessagesContainer',
    'teamMessagesContainer',
  ];

  static values = {
    userId: Number,
  };

  connect() {
    const projectId = this.element.dataset.projectId;
    this.channelConnected = false;
    this.channel = consumer.subscriptions.create(
      { channel: 'MessageChannel', project_id: projectId },
      {
        connected: this.#channelConnected.bind(this),
        disconnected: this.#channelDisconnected.bind(this),
        received: this.#channelReceived.bind(this),
      }
    );
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel);
  }

  toggle() {
    if (this.#open) {
      this.#readAllUnreadMessages();
    }
  }

  get #open() {
    return this.element.classList.contains('discussion-panel--active');
  }

  #channelConnected() {
    this.channelConnected = true;

    if (this.#open) {
      this.#readAllUnreadMessages();
    }
  }

  #readAllUnreadMessages() {
    if (this.#unreadMessagesCount > 0 && this.#channelConnected) {
      this.channel.perform('read_all');
    }
  }

  #channelDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  #channelReceived(data) {
    // Called when message is destroyed
    if (data.action === 'destroy') {
      deleteMessage(data.id);
      return;
    }

    if (data.action === 'update') {
      updateMessage(data);
      return;
    }

    // Called when there's incoming data on the websocket for this channel

    // add the message
    if (data.kind === 'client') {
      this.clientMessagesContainerTarget.innerHTML += data.message;
    } else if (data.kind === 'team') {
      this.teamMessagesContainerTarget.innerHTML += data.message;
    }

    if (this.#open) {
      if (data.user_id !== this.userIdValue) {
        this.channel.perform('read', { discussion_id: data.discussion_id });
      }
    } else {
      if (data.user_id !== this.userIdValue) {
        this.#unreadMessagesCount = this.#unreadMessagesCount + 1;
        this.unreadCountTarget.classList.toggle('hide', false);
      }
    }

    // add author class and filter by thread
    validateMessageBeforeShow(data.id);
  }

  get #unreadMessagesCount() {
    return parseInt(this.unreadCountTarget.innerText);
  }

  set #unreadMessagesCount(value) {
    return (this.unreadCountTarget.innerText = value);
  }
}
