import Blockquote from '@tiptap/extension-blockquote';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const control = menuControlFor(editor, 'blockquote:toggle');
  const controlIsActive = editor.isActive('blockquote');

  control?.classList?.toggle('active', controlIsActive);
};

export default [
  Blockquote.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
