import FontFamily from '@tiptap/extension-font-family';
import { menuControlFor, activeMenuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const font = editor.getAttributes('textStyle').fontFamily;
  const fontFamily = (font || 'Serif').replace(/["']/g, '');
  const previousFontControl = activeMenuControlFor(editor, 'font:family');
  const currentFontControl = menuControlFor(editor, 'font:family', fontFamily);

  if (previousFontControl !== currentFontControl) {
    previousFontControl?.classList?.toggle('active', false);
    currentFontControl?.classList?.toggle('active', true);
  }
};

export default [
  FontFamily.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
