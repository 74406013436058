const alignText = (editor, { value: position }) => {
  if (position === 'left') {
    editor.chain().focus().unsetTextAlign().run();
  } else {
    const validPosition = ['center', 'right', 'justify'].includes(position);

    validPosition && editor.chain().focus().setTextAlign(position).run();
  }
};

export default {
  'text:align': alignText,
};
