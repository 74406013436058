import Heading from '@tiptap/extension-heading';
import { menuControlFor, activeMenuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const headingLevel = editor.getAttributes('heading').level || -1;
  const previousControl = activeMenuControlFor(editor, 'text:heading');
  const currentControl = menuControlFor(editor, 'text:heading', headingLevel);

  if (previousControl !== currentControl) {
    previousControl?.classList?.toggle('active', false);
    currentControl?.classList?.toggle('active', true);
  }
};

export default [
  Heading.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
