/**
 * @param {HTMLTemplateElement} template
 * @param {Number} expectedChildrenCount
 * @throws {Error} if amount of children is different from expected
 * @return {Element|Array<Element>} if expectedChildrenCount === 1 returns node, otherwise array of nodes
 */
const templateContent = (template, expectedChildrenCount = 1) => {
  const children = template.content.children;

  if (expectedChildrenCount !== children.length) {
    throw new Error(
      `template contained ${template.children.length} children while expected ${expectedChildrenCount}`
    );
  }

  if (expectedChildrenCount === 1) {
    return children[0].cloneNode(true);
  }

  return [...children].map((el) => el.cloneNode(true));
};

export default templateContent;
