import { Controller } from 'stimulus';
import { prepareEventData } from './alerts_controller';

/**
 * Handles form autosubmit on various actions, with optional integration with Alerts
 * @example Basic usage
 *   form[data-controller="autosubmit" action="/users" method="post"]
 *    /! saves as you type
 *    input[data-action="autosubmit#submit"]
 *    /! `handler` targets become disabled until request finishes
 *    input[data-autosubmit-target="handler" data-action="autosubmit#submit"]
 *
 * @example Display alert on success (if some parent element has Alerts controller)
 *   form[data-controller="autosubmit" action="/users" method="post"]
 *    template[data-autosubmit-target="alertSuccess"]
 *      div.flash Success!
 */
export default class extends Controller {
  static targets = ['handler', 'alertSuccess'];

  connect() {
    if (!(this.element instanceof HTMLFormElement)) {
      throw new Error('autosubmit controller should be assigned to the form only!');
    }
  }

  submit() {
    const data = new FormData(this.element);
    this.#disabled = true;

    const request = fetch(this.#url, {
      method: this.#method,
      body: data,
    });

    request
      .then((response) => response.json())
      .then(({ id, html }) => {
        const element = document.getElementById(id);
        if (!element) {
          return;
        }

        if (this.hasAlertSuccessTarget) {
          this.dispatch('success', prepareEventData(this.alertSuccessTarget));
        }

        const template = document.createElement('template');
        template.innerHTML = html;
        // if there was an alert shown, give event a time to bubble up
        setTimeout(() => element.replaceWith(template.content));
      })
      .catch((error) => console.error(error));

    this.#disabled = false;
  }

  set #disabled(value) {
    this.handlerTargets.forEach((element) => {
      element.disabled = value;
    });
  }

  get #url() {
    return this.element.action;
  }

  get #method() {
    return this.element.method;
  }
}
