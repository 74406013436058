import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    filter: String,
    val: String,
  };

  unfilter() {
    this.dispatch(`${this.filterValue}:unfilter`, {
      detail: { filter: this.filterValue, value: this.valValue },
      bubbles: true,
    });
  }
}
