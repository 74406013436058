import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['textarea'];
  static values = { minHeight: Number };

  connect() {
    this.setMinHeight();
    this.resize();
  }

  setMinHeight() {
    if (this.hasMinHeightValue) {
      this.textareaTarget.style.minHeight = `${this.minHeightValue}px`;
    }
  }

  resize() {
    if (this.hasTextareaTarget) {
      this.textareaTarget.style.height = 'auto'; // Reset height to recalculate
      const newHeight = Math.max(this.textareaTarget.scrollHeight, this.minHeightValue || 0);
      this.textareaTarget.style.height = `${newHeight}px`;
    }
  }
}
