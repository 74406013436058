// TODO: Ricardo will extract this out

const tableRowsMenu = `
<div class="flex-dropdown-menu" data-controller="dropdown" data-js-visibility-target>
  <div class="table-menu-controls" data-js-visibility-trigger
    onmousedown="event.preventDefault()">
    <button type="button"
            class="flex-dropdown-menu__label"
            data-action="dropdown#toggle">
      <i class="fa-regular fa-ellipsis"></i>
    </button>
    <button onmousedown="event.preventDefault()"
            type="button"
            title="Add a row after the selected/focused rows"
            data-action="tiptap-editor#runCommand"
            data-command="table:rows:transform" value="append">
      <i class="fa-regular fa-plus"></i>
    </button>
  </div>
  <div class="flex-dropdown-menu__content">
    <div class="flex-dropdown-menu__links">
      <button onmousedown="event.preventDefault()"
              type="button"
              title="Add a row before the selected/focused rows"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:rows:transform" value="prepend">
        <i class="fa-regular fa-arrow-up-to-line"></i>
        Add Row Before
      </button>
      <button onmousedown="event.preventDefault()"
              type="button"
              title="Add a row after the selected/focused rows"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:rows:transform" value="append">
        <i class="fa-regular fa-arrow-down-to-line"></i>
        Add Row After
      </button>
      <button onmousedown="event.preventDefault()"
              type="button"
              title="Set/unset the first table row as Header"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:header:toggle" value="row">
        <i class="fa-regular fa-table-columns"></i>
        Toggle Header Row
      </button>
      <button onmousedown="event.preventDefault()"
              type="button"
              title="Delete the current or the selected rows"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:rows:transform" value="remove">
        <i class="fa-regular fa-trash"></i>
        Delete Row
      </button>
    </div>
  </div>
</div>
`;

const tableColumnsMenu = `
<div class="flex-dropdown-menu" data-controller="dropdown" data-js-visibility-target>
  <div class="table-menu-controls" data-js-visibility-trigger onmousedown="event.preventDefault()">
    <button onmousedown="event.preventDefault()"
            type="button"
            class="flex-dropdown-menu__label"
            data-action="dropdown#toggle">
      <i class="fa-regular fa-ellipsis"></i>
    </button>
    <button onmousedown="event.preventDefault()"
            type="button"
            title="Add a column after the selected/focused columns"
            data-action="tiptap-editor#runCommand"
            data-command="table:columns:transform" value="append">
      <i class="fa-regular fa-plus"></i>
    </button>
  </div>
  <div class="flex-dropdown-menu__content">
    <div class="flex-dropdown-menu__links">
      <button onmousedown="event.preventDefault()"
              type="button"
              title="Add a column before the selected/focused columns"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:columns:transform" value="prepend">
        <i class="fa-regular fa-arrow-left-to-line"></i>
        Add Column Before
      </button>
      <button onmousedown="event.preventDefault()"
              type="button"
              title="Add a column before the selected/focused columns"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:columns:transform" value="append">
        <i class="fa-regular fa-arrow-right-to-line"></i>
        Add Column After
      </button>
      <button onmousedown="event.preventDefault()"
              type="button"
              title="Set/unset the first table column as Header"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:header:toggle" value="column">
        <i class="fa-regular fa-table-rows"></i>
        Toggle Header Column
      </button>
      <button onmousedown="event.preventDefault()"
              type="button"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:header:toggle" value="cell">
        <i class="fa-regular fa-diagram-cells"></i>
        Toggle Header Cell
      </button>
      <button onmousedown="event.preventDefault()"
              type="button"
              title="Delete the current or the selected columns"
              data-action="dropdown#toggle tiptap-editor#runCommand"
              data-command="table:columns:transform" value="remove">
        <i class="fa-regular fa-trash"></i>
        Delete Column
      </button>
    </div>
  </div>
</div>
`;

const tableCellsMergeMenu = `
<div class="table-floating-menu" data-js-visibility-trigger data-js-visibility-target
  onmousedown="event.preventDefault()">
  <button type="button"
          class="tooltip"
          data-action="tiptap-editor#runCommand"
          data-command="table:cells:transform" value="merge">
    <i class="fa-kit fa-merge-cells"></i>
    <div class="tooltip__content">Merge Cells</div>
  </button>
  <button type="button"
          class="tooltip"
          data-action="tiptap-editor#runCommand"
          data-command="table:cells:transform" value="clear">
    <i class="fa-regular fa-circle-x"></i>
    <div class="tooltip__content">Clear Contents</div>
  </button>
</div>
`;

const tableCellsSplitMenu = `
<div class="table-floating-menu" data-js-visibility-trigger data-js-visibility-target
  onmousedown="event.preventDefault()">
  <button type="button"
          class="tooltip"
          data-action="tiptap-editor#runCommand"
          data-command="table:cells:transform" value="split">
    <i class="fa-kit fa-split-cells"></i>
    <div class="tooltip__content">Split Cells</div>
  </button>
  <button type="button"
          class="tooltip"
          data-action="tiptap-editor#runCommand"
          data-command="table:cells:transform" value="clear">
    <i class="fa-regular fa-circle-x"></i>
    <div class="tooltip__content">Clear Contents</div>
  </button>
</div>
`;

export { tableRowsMenu, tableColumnsMenu, tableCellsMergeMenu, tableCellsSplitMenu };
