import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'optionTemplate', 'picker'];

  static values = {
    options: Array,
  };

  connect() {
    this.#populate('');
  }

  search(event) {
    this.#populate(event.target.value);
  }

  pick(event) {
    this.#setInputValue(event.params.value);
  }

  clearOption() {
    this.#setInputValue('');
  }

  #setInputValue(value) {
    this.inputTarget.value = value;
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }));
  }

  #populate(query) {
    const opts = this.#findOptions(query);

    this.pickerTarget.innerHTML = '';

    opts.forEach((option) => {
      const templateContent = this.optionTemplateTarget.content.cloneNode(true);
      const optionElement = templateContent.querySelector('span');

      optionElement.textContent = option[0];
      optionElement.setAttribute('data-select-with-searchbar-value-param', option[1]);

      this.pickerTarget.appendChild(optionElement);
    });
  }

  #findOptions(query) {
    let selectedOptions;

    if (query) {
      const lowerQuery = query.toLowerCase();
      selectedOptions = this.optionsValue.filter(([text, _value]) =>
        text.toLowerCase().includes(lowerQuery)
      );
    } else {
      selectedOptions = this.optionsValue;
    }

    return selectedOptions;
  }
}
