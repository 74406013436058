import { Controller } from 'stimulus';

export default class extends Controller {
  #menuGroupSyncer;
  static values = { controlActivatesGroup: Boolean };
  static targets = ['groupStatus', 'control', 'group'];

  connect() {
    const criteria = { attributes: true, attributeFilter: ['class'], subtree: true };
    this.#menuGroupSyncer = this.#syncGroupOnControlStatusChange();
    this.#menuGroupSyncer.observe(this.element, criteria);
  }

  disconnet() {
    this.#menuGroupSyncer.disconnect();
  }

  #syncGroupOnControlStatusChange() {
    return new MutationObserver((mutations, _) => {
      const mutationInControl = (mutation) => this.controlTargets.includes(mutation.target);
      const menuControlChanged = mutations.some(mutationInControl);

      menuControlChanged && this.#updateMenuGroupState();
    });
  }

  #updateMenuGroupState() {
    const controlIsActive = (control) => control.classList.contains('active');
    const activeControl = this.controlTargets.find(controlIsActive);

    if (activeControl) {
      this.groupStatusTarget.innerHTML = activeControl.innerHTML;
      this.controlActivatesGroupValue && this.groupTarget.classList.add('active');
    } else {
      this.controlActivatesGroupValue && this.groupTarget.classList.remove('active');
    }
  }
}
