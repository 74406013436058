import Image from 'tiptap-extension-resize-image';
import FileHandler from '@tiptap-pro/extension-file-handler';
import { heicTo } from 'heic-to';

const heicToJpeg = (blob) => heicTo({ blob, type: 'image/jpeg', quality: 0.5 });

const convertImageToCompatibleFormat = async (file) => {
  const isHeicImage = ['image/heic', 'image/heif'].includes(file.type);
  return isHeicImage ? await heicToJpeg(file) : file;
};

const getEditorWidth = (editor) => {
  const editorElement = editor.view.dom;
  const pageElement = editorElement.closest('.tiptap-editor__page');
  const styles = window.getComputedStyle(pageElement);
  const paddingLeft = parseFloat(styles.paddingLeft);
  const paddingRight = parseFloat(styles.paddingRight);

  return pageElement.clientWidth - paddingLeft - paddingRight;
};

const insertImageOnEditor = (editor, fileReader, pos) => {
  const editorWidth = getEditorWidth(editor);

  return () => {
    editor
      .chain()
      .insertContentAt(pos, {
        type: 'image',
        attrs: {
          src: fileReader.result,
          style: `width: ${editorWidth}px; height: auto;`,
        },
      })
      .focus()
      .run();
  };
};

const convertImagesAndInsert = async (editor, files, position) => {
  for (const file of files) {
    const compatibleImage = await convertImageToCompatibleFormat(file);
    const fileReader = new FileReader();
    fileReader.onload = insertImageOnEditor(editor, fileReader, position);
    fileReader.readAsDataURL(compatibleImage);
  }
};

// Image extension does not care about html content being pasted
const insertImagesOrIgnoreIfHtml = (editor, files, htmlContent) => {
  const position = editor.state.selection.anchor;
  return htmlContent ? false : convertImagesAndInsert(editor, files, position);
};

const keepImageFocused = ({ editor, transaction }) => {
  const steps = transaction.steps;
  const oneMutation = steps.length === 1;
  const changedNode = steps[0]?.slice?.content?.content?.[0];

  if (!(oneMutation && changedNode && changedNode.type.name === 'image')) {
    return;
  }

  editor.state.doc.descendants((node, pos) => {
    changedNode === node && editor.commands.setNodeSelection(pos);
  });

  requestAnimationFrame(() => {
    editor.view.dom.querySelector('.ProseMirror-selectednode img').click();
  });
};

const fileHandlerConfiguration = {
  allowedMimeTypes: [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/webp',
    'image/heic',
    'image/heif',
  ],
  onDrop: convertImagesAndInsert,
  onPaste: insertImagesOrIgnoreIfHtml,
};

// TODO: [Ricardo] Upload these images instead of storing a base64 representation
const imageConfiguration = {
  inline: true,
  allowBase64: true,
};

const ImageExtension = Image.extend({ onSelectionUpdate: keepImageFocused });

const imageExtensions = [
  ImageExtension.configure(imageConfiguration),
  FileHandler.configure(fileHandlerConfiguration),
];

export default imageExtensions;
