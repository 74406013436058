import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selectedOptions', 'option'];
  static values = { placeholder: String };

  connect() {
    this.updatePlaceholder();
  }

  toggle() {
    this.#opened = !this.#opened;
  }

  updatePlaceholder() {
    const selectedOptions = this.optionTargets.filter(this.#withOptionSelected);
    const selectedNames = selectedOptions.map(this.#nameForOption);
    const placeholder = selectedNames.join(', ');

    this.selectedOptionsTarget.innerText = placeholder || this.placeholderValue;
  }

  get #opened() {
    return this.element.classList.contains('multi-select--open');
  }

  set #opened(value) {
    this.element.classList.toggle('multi-select--open', value);
  }

  #withOptionSelected(option) {
    return option.querySelector('input[type="checkbox"]:checked');
  }

  #nameForOption(option) {
    return option.querySelector('label').innerText;
  }
}
