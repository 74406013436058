import History from '@tiptap/extension-history';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const undoControl = menuControlFor(editor, 'editor:undo');
  const redoControl = menuControlFor(editor, 'editor:redo');

  if (undoControl && redoControl) {
    undoControl.disabled = !editor.can().undo();
    redoControl.disabled = !editor.can().redo();
  }
};

export default [
  History.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
