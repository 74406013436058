import { Controller } from 'stimulus';
import { useTimeout } from '@/js/util';

/**
 * @example Alert that is removed automatically after delay (time is in seconds)
 *   div[data-controller="alert" data-alert-dismiss-timeout-value="5"]
 *     I will be gone after 5 seconds
 */
export default class extends Controller {
  static values = {
    dismissTimeout: Number,
  };

  connect() {
    useTimeout(this);

    if (this.hasDismissTimeoutValue) {
      this.timeout(this.dismissTimeoutValue * 1000, () => this.element.remove());
    }
  }
}
