import { Controller } from 'stimulus';
export default class extends Controller {
  static values = {
    isMouseDown: Boolean,
    InitialMousePosition: Object,
    InitialElementTranslate: Object,
  };
  connect() {
    this.InitialMousePositionValue = { x: 0, y: 0 };
    this.initialElementTranslateValue = { x: 0, y: 0 };
  }
  drag(event) {
    if (!this.isMouseDownValue) {
      return;
    }
    window.requestAnimationFrame(() => {
      const newTranslateX =
        this.initialElementTranslateValue.x + event.clientX - this.initialMousePositionValue.x;
      const newTranslateY =
        this.initialElementTranslateValue.y + event.clientY - this.initialMousePositionValue.y;
      this.element.style.transform = `translate(${newTranslateX}px, ${newTranslateY}px)`;
    });
  }

  mouseDown(event) {
    const computedStyle = window.getComputedStyle(this.element);
    const matrix = new DOMMatrix(computedStyle.transform);

    this.initialElementTranslateValue = { x: matrix.m41, y: matrix.m42 };
    this.initialMousePositionValue = { x: event.clientX, y: event.clientY };
    this.isMouseDownValue = true;
  }

  mouseUp() {
    this.#isMouseDown = false;
  }

  get #isMouseDown() {
    return this.isMouseDownValue;
  }

  set #isMouseDown(val) {
    this.isMouseDownValue = val;
  }
}
