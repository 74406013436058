import DesignerNotesConfig from './designer_notes_config';
import TextProcessorConfig from './text_processor_config';
import CompactConfig from './compact_config';

export default class Config {
  static for(variant) {
    switch (variant) {
      case 'designer_notes':
        return new DesignerNotesConfig();
      case 'standard':
        return new TextProcessorConfig();
      case 'compact':
        return new CompactConfig();
      default:
        return new TextProcessorConfig(); // This will change in next PR
    }
  }
}
