import { Controller } from 'stimulus';

export default class extends Controller {
  uncheck(event) {
    if (event.detail.value === this.element.value) {
      this.element.checked = false;
      this.element.dispatchEvent(new Event('change'));
    }
  }
}
