const toolbarFor = (editor) => {
  const app = editor.view.dom.closest('[role="application"]');
  return app?.querySelector('[role="toolbar"]');
};

const selectorFor = (command, value) => {
  if (value) {
    return `[data-command="${command}"][value="${value}"]`;
  }
  return `[data-command="${command}"]`;
};

const menuControlFor = (editor, command, value) => {
  const selector = selectorFor(command, value);
  return toolbarFor(editor)?.querySelector(selector);
};

const activeMenuControlFor = (editor, command, value) => {
  const selector = selectorFor(command, value);
  return toolbarFor(editor)?.querySelector(`${selector}.active`);
};

export { menuControlFor, activeMenuControlFor };
