import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['a', 'b'];

  switch() {
    this.aTarget.classList.toggle('hidden');
    this.bTarget.classList.toggle('hidden');
  }
}
