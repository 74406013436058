const toggleListMapping = {
  unordered: (editor) => editor.toggleBulletList().run(),
  ordered: (editor) => editor.toggleOrderedList().run(),
};

const toggleList = (editor, button) => {
  toggleListMapping[button.value](editor.chain().focus());
};

export default {
  'list:toggle': toggleList,
};
