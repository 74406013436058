const editorUndo = (editor) => {
  editor.chain().focus().undo().run();
};

const editorRedo = (editor) => {
  editor.chain().focus().redo().run();
};

export default {
  'editor:undo': editorUndo,
  'editor:redo': editorRedo,
};
