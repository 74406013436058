import Italic from '@tiptap/extension-italic';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const control = menuControlFor(editor, 'text:formatting', 'italic');
  const controlIsActive = editor.isActive('italic');

  control?.classList?.toggle('active', controlIsActive);
};

export default [
  Italic.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
