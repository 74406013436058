export default class {
  #targetInputField;

  constructor(targetInputField) {
    this.#targetInputField = targetInputField;
  }

  write({ editor }) {
    console.debug('Serializing document');

    this.#targetInputField.value = editor.getHTML();
  }

  read() {
    console.debug('Deserializing document');

    return this.#targetInputField.value;
  }
}
