import Underline from '@tiptap/extension-underline';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const control = menuControlFor(editor, 'text:formatting', 'underline');
  const controlIsActive = editor.isActive('underline');

  control?.classList?.toggle('active', controlIsActive);
};

export default [
  Underline.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
