import { Controller } from 'stimulus';

/**
 * @param {HTMLTemplateElement} template
 * @return {{detail: {alerts: Array<Element>}}} event configuration
 */
export const prepareEventData = (template) => {
  template = template.cloneNode(true);
  const alerts = Array.from(template.content.children);
  return { detail: { alerts } };
};

/**
 * Handles underlying custom events to show alerts on actions.
 * Use {@link prepareEventData} to dispatch event from your controller.
 * @example
 *  div[data-controller="alerts" data-action="my-controller:something->alerts#show"]
 *    div[data-alerts-target="container"]
 */
export default class extends Controller {
  static targets = ['container'];

  /**
   * @param {CustomEvent} event
   */
  show(event) {
    event.stopPropagation();

    const { detail } = event;
    const { alerts } = detail;

    alerts.forEach((alert) => this.containerTarget.appendChild(alert));
  }
}
