import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['expandIcon', 'expansible'];

  static classes = ['openIcon', 'closeIcon', 'expansibleOpen', 'expansibleClosed'];

  static values = {
    isOpen: Boolean,
  };

  toggle(event) {
    const targetIsNotALink = !event.target.closest('a');

    if (targetIsNotALink) {
      this.opened = !this.opened;
    }
  }

  get opened() {
    return this.isOpenValue;
  }

  set opened(opened) {
    this.isOpenValue = opened;
    this.expansibleTarget.classList.toggle(this.expansibleOpenClass, this.isOpenValue);
    this.expansibleTarget.classList.toggle(this.expansibleClosedClass, !this.isOpenValue);
    this.expandIconTarget.classList.toggle(this.closeIconClass, this.isOpenValue);
    this.expandIconTarget.classList.toggle(this.openIconClass, !this.isOpenValue);
  }
}
