import Color from '@tiptap/extension-color';
import { menuControlFor } from './_get_menu_control';

const rgbToHex = (color) => {
  const hexNumber = color
    .match(/\d+/g)
    .slice(0, 3)
    .map((num) => parseInt(num, 10).toString(16).padStart(2, '0'))
    .join('');

  return `#${hexNumber}`;
};

const syncMenu = ({ editor }) => {
  const color = editor.getAttributes('textStyle').color || '#000000';
  const colorInput = menuControlFor(editor, 'text:color');

  if (colorInput) {
    colorInput.value = color.startsWith('rgb') ? rgbToHex(color) : color;
  }
};

export default [
  Color.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
