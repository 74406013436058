import TiptapTaskList from '@tiptap/extension-task-list';
import TiptapTaskItem from '@tiptap/extension-task-item';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const control = menuControlFor(editor, 'task-list:toggle');
  const controlIsActive = editor.isActive('taskList');

  control?.classList?.toggle('active', controlIsActive);
};

const TaskListExtension = TiptapTaskList.extend({
  onSelectionUpdate: syncMenu,
  onTransaction: syncMenu,
});

const TaskItemExtension = TiptapTaskItem.configure({
  nested: true,
});

const taskListExtensions = [TaskListExtension, TaskItemExtension];

export default taskListExtensions;
