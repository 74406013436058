import { Controller } from 'stimulus';
import { FetchRequest } from '@rails/request.js';

/**
 * Handles form autosubmit on various actions with turbo-stream format
 * @example Basic usage
 *   form[data-controller="autosubmit" action="/users" method="post"]
 *    /! saves as you type
 *    input[data-action="autosubmit#submit"]
 *    /! `handler` targets become disabled until request finishes when the disableOnSubmit value is set to true
 *    input[data-autosubmit-target="handler" data-action="autosubmit#submit"]
 */
export default class extends Controller {
  static targets = ['handler'];

  static values = {
    disableOnSubmit: false,
    submitDebounce: 500,
  };

  connect() {
    if (!(this.element instanceof HTMLFormElement)) {
      throw new Error('autosubmit controller should be assigned to the form only!');
    }

    this.submitDebounced = window.jQuery.debounce(
      this.submitDebounced.bind(this),
      this.submitDebounceValue
    );
  }

  async submitDebounced() {
    await this.submit();
  }

  async submit() {
    const data = new FormData(this.element);
    this.#disabled = true;

    if (this.#method === 'get') {
      const params = new URLSearchParams(data);
      const url = `${this.#url}?${params.toString()}`;
      await new FetchRequest(this.#method, url, {
        responseKind: 'turbo-stream',
      }).perform();
    } else {
      await new FetchRequest(this.#method, this.#url, {
        body: data,
        responseKind: 'turbo-stream',
      }).perform();
    }

    this.#disabled = false;
  }

  async clearForm() {
    this.element.reset();
    await this.submit();
  }

  set #disabled(value) {
    if (!this.disableOnSubmitValue) {
      return;
    }

    this.handlerTargets.forEach((element) => {
      element.disabled = value;
    });
  }

  get #url() {
    return this.element.action;
  }

  get #method() {
    return this.element.method;
  }
}
