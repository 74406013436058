import BulletList from '@tiptap/extension-bullet-list';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const control = menuControlFor(editor, 'list:toggle', 'unordered');
  const controlIsActive = editor.isActive('bulletList');

  control?.classList?.toggle('active', controlIsActive);
};

export default [
  BulletList.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
