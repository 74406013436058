const textFormattingMapping = {
  bold: (editor) => editor.toggleBold().run(),
  italic: (editor) => editor.toggleItalic().run(),
  underline: (editor) => editor.toggleUnderline().run(),
  strike: (editor) => editor.toggleStrike().run(),
};

const formatText = (editor, button) => {
  textFormattingMapping[button.value](editor.chain().focus());
};

export default {
  'text:formatting': formatText,
};
