import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tag', 'showAll', 'hideRemainder', 'clear'];

  static values = {
    displayedTags: Number,
    channelId: String,
  };

  showAll() {
    this.tagTargets.forEach((tag) => {
      tag.classList.remove('hidden');
    });
    this.showAllTarget.classList.add('hidden');
    this.hideRemainderTarget.classList.remove('hidden');
    this.clearTarget.classList.remove('hidden');
  }

  hideRemainder() {
    this.tagTargets.slice(this.displayedTagsValue, this.tagTargets.length).forEach((tag) => {
      tag.classList.add('hidden');
    });
    this.showAllTarget.classList.remove('hidden');
    this.hideRemainderTarget.classList.add('hidden');
    this.clearTarget.classList.add('hidden');
  }

  clearAll() {
    this.dispatch(`clear:${this.channelIdValue}`);
  }
}
