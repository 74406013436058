import { Controller } from 'stimulus';

export default class extends Controller {
  #closeWhenClickedOutside;

  connect() {
    this.#closeWhenClickedOutside = this.#closeIfOutsideClick.bind(this);
    document.addEventListener('click', this.#closeWhenClickedOutside);
  }

  disconnect() {
    document.removeEventListener('click', this.#closeWhenClickedOutside);
  }

  toggle() {
    this.#opened = !this.#opened;
  }

  get #opened() {
    return this.element.classList.contains('flex-dropdown-menu--open');
  }

  set #opened(value) {
    this.element.classList.toggle('flex-dropdown-menu--open', value);
  }

  #closeIfOutsideClick(event) {
    const keepOpened = this.element.contains(event.target) && this.#opened;
    this.#opened = keepOpened;
  }
}
