const chainDisconnect = (controller, disconnect) => {
  if (!controller.disconnect) {
    return disconnect;
  }

  const originalDisconnect = controller.disconnect.bind(controller);

  return () => {
    disconnect();
    originalDisconnect();
  };
};

/**
 * Adds timeouts to your controllers with automatic clearing on disconnect
 * @example Usage
 *   class MyController extends Controller {
 *     connect() {
 *       useTimeout(this)
 *     }
 *
 *     send() {
 *       this.timeout(2000, () => doSomething())
 *     }
 *   }
 *
 * @param {Object} controller
 * @return {void}
 */
const useTimeout = (controller) => {
  const timeoutIds = new Set();

  /**
   * @param {Number} delay
   * @param {Function} callback
   */
  const timeout = (delay, callback) => {
    const timeoutId = setTimeout(() => {
      timeoutIds.delete(timeoutId);
      callback.call(controller);
    }, delay);
    timeoutIds.add(timeoutId);
  };

  const disconnect = () => {
    if (timeoutIds.size) {
      timeoutIds.forEach(clearTimeout);
      timeoutIds.clear();
    }
  };

  /** @type {timeout} */
  controller.timeout = timeout;
  controller.disconnect = chainDisconnect(controller, disconnect);
};

export default useTimeout;
