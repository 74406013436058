import LinkExtension from '@tiptap/extension-link';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const control = menuControlFor(editor, 'link:set');
  const controlIsActive = editor.isActive('link');

  control?.classList?.toggle('active', controlIsActive);
};

const linkConfig = {
  openOnClick: false,
  autoLink: true,
  HTMLAttributes: {
    target: '_blank',
  },
};

const Link = LinkExtension.extend({
  onSelectionUpdate: syncMenu,
  onTransaction: syncMenu,
});

export default [Link.configure(linkConfig)];
