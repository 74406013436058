import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['clearButton', 'input'];

  toggleClearButton(event) {
    this.clearButtonTarget.classList.toggle('hidden', event.target.value === '');
  }

  clear() {
    this.inputTarget.value = '';
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }));
    this.clearButtonTarget.classList.add('hidden');
  }
}
