import { Controller } from 'stimulus';

export default class extends Controller {
  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  /**
   * @param {BeforeUnloadEvent} e
   */
  show(e) {
    if (!this.enabled) {
      return;
    }

    e.preventDefault();
  }
}
