import {
  TiptapBase,
  Heading,
  Blockquote,
  BulletList,
  OrderedList,
  Bold,
  Color,
  Italic,
  Strike,
  History,
  FontFamily,
  Link,
  Underline,
  TextAlign,
  HardBreak,
  HorizontalRule,
  ListItem,
  TextStyle,
  TaskLists,
} from '@/js/util/tiptap/extensions';

import {
  BlockquoteHandlers,
  FontFamilyHandlers,
  HeadingLevelHandlers,
  HistoryHandlers,
  HorizontalRuleHandlers,
  LinksHandlers,
  ListsHandlers,
  TaskListHandlers,
  TextAlignmentHandlers,
  TextColorHandlers,
  TextFormattingHandlers,
} from '@/js/util/tiptap/handlers';

export default class DesignerNotesConfig {
  get extensions() {
    return [
      ...TiptapBase,
      ...Heading,
      ...Blockquote,
      ...BulletList,
      ...OrderedList,
      ...Bold,
      ...Color,
      ...Italic,
      ...Strike,
      ...History,
      ...FontFamily,
      ...Link,
      ...Underline,
      ...TextAlign,
      ...HardBreak,
      ...HorizontalRule,
      ...ListItem,
      ...TextStyle,
      ...TaskLists,
    ];
  }

  get handlers() {
    return {
      ...BlockquoteHandlers,
      ...FontFamilyHandlers,
      ...HeadingLevelHandlers,
      ...HistoryHandlers,
      ...HorizontalRuleHandlers,
      ...LinksHandlers,
      ...ListsHandlers,
      ...TextAlignmentHandlers,
      ...TextColorHandlers,
      ...TextFormattingHandlers,
      ...TaskListHandlers,
    };
  }
}
