import OrderedList from '@tiptap/extension-ordered-list';
import { menuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const control = menuControlFor(editor, 'list:toggle', 'ordered');
  const controlIsActive = editor.isActive('orderedList');

  control?.classList?.toggle('active', controlIsActive);
};

export default [
  OrderedList.extend({
    onSelectionUpdate: syncMenu,
    onTransaction: syncMenu,
  }),
];
