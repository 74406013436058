import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'content', 'chevron'];

  static values = {
    isOpen: false,
  };

  connect() {
    this.#open = this.isOpenValue;
  }

  hideOthers(event) {
    if (!this.element.contains(event.target)) {
      this.hide();
    }
  }

  hide() {
    this.#open = false;
  }

  toggle(event) {
    this.#open = !this.#open;
    event.preventDefault();
  }

  get #open() {
    return this.isOpenValue;
  }

  set #open(value) {
    this.isOpenValue = value;

    this.buttonTarget.classList.toggle('bg-df-blue-dark', this.isOpenValue);
    this.element.classList.toggle('text-white', this.isOpenValue);
    this.chevronTarget.classList.toggle('text-white', this.isOpenValue);

    this.chevronTarget.classList.toggle('-scale-y-100', !this.isOpenValue);
    this.element.classList.toggle('text-df-blue-dark', !this.isOpenValue);
    this.contentTarget.classList.toggle('hidden', !this.isOpenValue);
  }
}
