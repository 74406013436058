import TiptapEditor from '@/js/util/tiptap/editor';
import { Controller } from 'stimulus';

export default class extends Controller {
  #editor;

  connect() {
    this.#editor = new TiptapEditor(this._element, this._extensions, this._serializer);
    this.#registerCommands();
  }

  disconnect() {
    this.#editor._destroy();
  }

  runCommand(event) {
    this.#editor.runCommand(event);
  }

  #registerCommands() {
    Object.entries(this._commandHandlers).forEach(([command, handler]) => {
      this.#editor.registerCommand(command, handler);
    });
  }

  get _element() {
    throw new Error('get _element() must be implemented');
  }

  get _serializer() {
    throw new Error('get _serializer() must be implemented');
  }

  get _extensions() {
    throw new Error('get _extensions() must be implemented');
  }

  get _commandHandlers() {
    throw new Error('get _commandHandlers() must be implemented');
  }
}
