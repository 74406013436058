import { findParentNode } from '@tiptap/core';

const addEmptyParagraphAfter = (editor, nodeType) => {
  const sameType = (node) => node.type.name === nodeType;
  const node = findParentNode(sameType)(editor.state.selection);
  const afterNodePos = node.pos + node.node.nodeSize;

  editor.chain().focus().insertContentAt(afterNodePos, '<p> </p>').run();
  editor.commands.setTextSelection(node.pos, node.pos);
  editor.commands.focus();
};

const addTable = (editor) => {
  editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
  addEmptyParagraphAfter(editor, 'table');
};

const clearTargetCells = (editor, state) => {
  if (state.selection.empty) {
    editor.setNodeSelection(state.selection.$anchor.before() + 1);
  }
  editor.deleteSelection().run();
};

const columnsTransformations = {
  prepend: (editor) => editor.addColumnBefore().run(),
  append: (editor) => editor.addColumnAfter().run(),
  remove: (editor) => editor.deleteColumn().run() || editor.deleteTable().run(),
};

const headerTransformations = {
  row: (editor) => editor.toggleHeaderRow().run(),
  column: (editor) => editor.toggleHeaderColumn().run(),
  cell: (editor) => editor.toggleHeaderCell().run(),
};

const rowsTransformations = {
  prepend: (editor) => editor.addRowBefore().run(),
  append: (editor) => editor.addRowAfter().run(),
  remove: (editor) => editor.deleteRow().run() || editor.deleteTable().run(),
};

const cellsTransformations = {
  split: (editor) => editor.splitCell().run(),
  merge: (editor) => editor.mergeCells().run(),
  clear: (editor, state) => clearTargetCells(editor, state),
};

const transformColumns = (editor, button) =>
  columnsTransformations[button.value](editor.chain().focus());

const toggleHeader = (editor, button) =>
  headerTransformations[button.value](editor.chain().focus());

const transformRows = (editor, button) => rowsTransformations[button.value](editor.chain().focus());

const transformCells = (editor, button) =>
  cellsTransformations[button.value](editor.chain().focus(), editor.state);

const tableHandlers = {
  'table:add': addTable,
  'table:header:toggle': toggleHeader,
  'table:columns:transform': transformColumns,
  'table:rows:transform': transformRows,
  'table:cells:transform': transformCells,
};

export default tableHandlers;
