/* global DfApi */
import escapeRegExp from 'lodash.escaperegexp';
const api = () => new DfApi();

const notFoundError = (_response) => {
  alert('Preset not found');
};

const emptyNameError = () => {
  alert('You need to provide a non-empty name');
};

// FIXME: Ricardo will investigate and decide where to put this
const replaceAll = (string, substitutions) => {
  const replacement = (result, [key, value]) => {
    return result.split(`{{${key}}}`).join(escapeRegExp(value));
  };

  return Object.entries(substitutions).reduce(replacement, string);
};

// FIXME: Ricardo will investigate and decide where to put this
const newElement = (dataJsTemplate, substitutions) => {
  const template = document.querySelector(`[data-js-template="${dataJsTemplate}"`);
  const element = template.content.cloneNode(true).firstElementChild;
  element.innerHTML = replaceAll(element.innerHTML, substitutions);

  return element;
};

const creationSuccess = async (response) => {
  const { id, title } = await response.json();
  const substitutions = { id, title };
  const presetGroup = newElement('preset-menu-control', substitutions);

  document.querySelector('.presets__collection').appendChild(presetGroup);
};

const creationError = (response, name) => {
  alert(`An error occurred while creating preset ${name}`);
};

const createPreset = async (editor, name) => {
  const details = editor.getHTML();
  const response = await api().createPreset(name, 'contract', details);

  response.ok ? creationSuccess(response) : creationError(response, name);
};

const writePresetToEditor = async (response, editor) => {
  const { tiptap_details: details } = await response.json();
  editor.commands.setContent(details, true);
};

const loadPreset = async (editor, button) => {
  const presetId = button.value;
  const response = await api().getPreset(presetId);

  response.ok ? writePresetToEditor(response, editor) : notFoundError(response);
};

const savePreset = (editor, button) => {
  const name = prompt('Enter a preset name');
  const emptyName = name.trim().length === 0;

  emptyName ? emptyNameError() : createPreset(editor, name);
};

const deletePreset = async (editor, button) => {
  const remove = confirm(`Do you want to delete ${button.dataset.name}?`);

  if (remove) {
    const presetId = button.value;
    const response = await api().deletePreset(presetId);

    response.ok ? button.parentElement.remove() : notFoundError(response);
  }
};

const presetHandlers = {
  'preset:load': loadPreset,
  'preset:save': savePreset,
  'preset:delete': deletePreset,
};

export default presetHandlers;
