'use strict';

export default class Util {
  static isDefined(val) {
    return typeof val !== 'undefined' && val !== null;
  }

  static isTouchScreen() {
    return 'ontouchstart' in window;
  }
}
