import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  static values = {
    index: 0,
  };

  connect() {
    this.#updateVisibility();
  }

  next() {
    this.indexValue = (this.indexValue + 1) % this.itemTargets.length;
    this.#updateVisibility();
  }

  previous() {
    this.indexValue = (this.indexValue - 1 + this.itemTargets.length) % this.itemTargets.length;
    this.#updateVisibility();
  }

  #updateVisibility() {
    this.itemTargets.forEach((item) => {
      item.classList.add('hidden');
    });

    const currentItem = this.itemTargets[this.indexValue];
    currentItem.classList.remove('hidden');

    this.dispatch('change', { detail: { ...currentItem.dataset } });
  }
}
