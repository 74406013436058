import TextAlignExtension from '@tiptap/extension-text-align';
import { menuControlFor, activeMenuControlFor } from './_get_menu_control';

const syncMenu = ({ editor }) => {
  const textAttr = { ...editor.getAttributes('heading'), ...editor.getAttributes('paragraph') };
  const alignment = textAttr.textAlign || 'left';

  const previousControl = activeMenuControlFor(editor, 'text:align');
  const currentControl = menuControlFor(editor, 'text:align', alignment);

  if (previousControl !== currentControl) {
    previousControl?.classList?.toggle('active', false);
    currentControl.classList.toggle('active', true);
  }
};

const textAlignConfig = {
  types: ['heading', 'paragraph'],
};

const TextAlign = TextAlignExtension.extend({
  onSelectionUpdate: syncMenu,
  onTransaction: syncMenu,
});

export default [TextAlign.configure(textAlignConfig)];
