import { Editor } from '@tiptap/core';

export default class {
  #editor;
  #commandHandlers = {};
  #extensions = [];

  constructor(element, extensions, serializer) {
    extensions.forEach(this.#registerExtension.bind(this));

    this.#editor = new Editor({
      element,
      extensions: this.#extensions,
      content: serializer.read(),
      onUpdate: serializer.write.bind(serializer),
    });
  }

  registerCommand(command, handler) {
    console.info(`Registering command ${command}`);
    const overridingHandler = `Overrode command handler for ${command}`;

    this.#commandHandlers[command] && console.warn(overridingHandler);
    this.#commandHandlers[command] = handler;
  }

  runCommand(event) {
    const target = event.currentTarget;
    const command = target.dataset.command;
    const handler = this.#commandHandlers[command] || this.#logUnknownCommand;

    handler.call(null, this.#editor, target);
  }

  _destroy() {
    this.#editor.destroy();
  }

  #registerExtension(extension) {
    console.info(`Registering extension ${extension.name}`);

    this.#extensions.push(extension);
  }

  #logUnknownCommand(_, target) {
    console.warn(`Ignored unknown command ${target.dataset.command}.`);
  }
}
